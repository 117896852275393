import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { EndpointsService } from "./endpoints.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, lastValueFrom, Observable, throwError } from "rxjs";
import { User } from "@shared/models/User";
import { UserRegisterDto } from "../../store/models/userRegisterDto";
import { SiteConfigDTO } from "@shared/models/SiteConfigDTO";
import { Area } from "@shared/models/Area";
import { FrontPageArea } from "@shared/models/FrontPageArea";
import { BrandingSettings } from '@shared/models';

type ApiResponse<R extends Record<string, any> = Record<string, any>> = {
	status: boolean;
	message: string;
	package?: R;
	payload?: R;
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private get apiUrl() {
    return environment.api_url;
  }

  constructor(private endpoints: EndpointsService,
              private httpClient: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was:`, error.error);
    }

    if (error.status === 401) {
      console.log(error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => 'Something went wrong; please try again later.')
  }

  public login(email: String, password: String) {
    const { login } = this.endpoints;
    const payload: object = {
      email,
      password
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post<User>(`${this.apiUrl}/${login}`, payload)
    .pipe(
      catchError(this.handleError)
    ));
  }

  public register(user: UserRegisterDto) {
    const { register } = this.endpoints;
    return lastValueFrom(
      this.httpClient.post<User>(`${this.apiUrl}/${register}`, user)
      .pipe(catchError(this.handleError))
    );
  }

  public async getAppSettings() {
    const { settings } = this.endpoints;
    const response = await lastValueFrom(
      this.httpClient.get<ApiResponse<{ data: SiteConfigDTO; }>>(`${this.apiUrl}/${settings}?clean=true`)
      .pipe(catchError(this.handleError))
    );
    if (response.status && response.package) {
      return response.package.data;
    }
    return {} as SiteConfigDTO;
  }

  public getAppSettingsRequest() {
    const { settings } = this.endpoints;
    return this.httpClient.get<ApiResponse<{ data: SiteConfigDTO }>>(`${this.apiUrl}/${settings}?clean=true`);//.pipe((data) => {console.log(data)});
  }

  public async getAreas() {
    const { areasEndpoint } = this.endpoints;
    const response = await lastValueFrom(
      this.httpClient.get<ApiResponse<Area[]>>(`${this.apiUrl}/${areasEndpoint}?clean=true`)
      .pipe(catchError(this.handleError))
    );
    if (response.status && response.package) {
      return response.package;
    }
    return [];
  }

  public async getFrontPageContent() {
    const { frontPageAreas } = this.endpoints;
    const response = await lastValueFrom(
      this.httpClient.get<ApiResponse<{ data: FrontPageArea[]; }>>(`${this.apiUrl}/${frontPageAreas}`)
      .pipe(catchError(this.handleError))
    );
    if (response.status && response.package) {
      return response.package.data;
    }
    return [];
  }

  public async getBrandingSettings() {
    const { brandingSettings } = this.endpoints;
    const response = await lastValueFrom(
      this.httpClient.get<ApiResponse<{ data: BrandingSettings; }>>(`${this.apiUrl}/${brandingSettings}`)
      .pipe(catchError(this.handleError))
    );
    if (response.status && response.package) {
      return response.package.data;
    }
    return {uuid: 'branding'};
  }

  public getAreasRequest() {
    const { areasEndpoint } = this.endpoints;
    return this.httpClient.get<{ package: Area[] }>(`${this.apiUrl}/${areasEndpoint}?clean=true`);
  }

  public forgotPassword(mail: string) {
    const { forgotpassword } = this.endpoints;
    const payload = {
      mail
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}/${forgotpassword}`, payload)
      .pipe(catchError(this.handleError))
    );
  }

  /**
   * Reset password.
   */
  public resetPassword(name: string, temp_pass: string, new_pass: string) {
    const { resetpassword } = this.endpoints;
    const payload = {
      name,
      temp_pass,
      new_pass
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}/${resetpassword}`, payload)
      .pipe(catchError(this.handleError))
    );
  }

  /**
   * ota sms code check
   */
  public otaSmsCodeCheck(mail: string, ota_code: string) {
    const { otaSmsCode } = this.endpoints;
    const payload = {
      mail,
      ota_code
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}/${otaSmsCode}`, payload)
      // TODO is this intended?
      .pipe(
        //catchError(this.handleError)
      )
    );
  }

  public resendOtpCode(mail_or_phone: string) {
    const { otaSmsEmailCodeResend } = this.endpoints;
    const payload = {
      mail_or_phone
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}/${otaSmsEmailCodeResend}`, payload)
      .pipe(catchError(this.handleError))
    );
  }

  /**
   * Update user profile Picture
   */
  public updateUserProfilePicture(_accessToken: string, base_64_image : string, file_name : string) {
    const { profileImageUpdate } = this.endpoints;
    const payload = {
      //accessToken,
      base_64_image,
      file_name
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}${profileImageUpdate}`, payload)
      .pipe(catchError(this.handleError))
    );
  }


  /**
   * Update user profile.
   */
  public updateUserProfile(_accessToken: string, user: User) {
    const { update_user_profile } = this.endpoints;
    // Split out non-update able fields
    const {
      accessToken,
      profilePicture,
      profilePictureSmall,
      ...updateFields
    } = user;
    const payload = {
      //accessToken,
      user: updateFields
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}${update_user_profile}`, payload)
      .pipe(catchError(this.handleError))
    );
  }

  /**
   * reports a user.
   * @param accessToken
   * @param blockUserUUID
   * @param type
   * @param reason
   */
  public reportUser(_accessToken: string, blockUserUUID: string, type: string, reason: string) {
    const { report_user } = this.endpoints;
    const payload = {
      blockUserUUID,
      type,
      reason
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}${report_user}`, payload)
      .pipe(catchError(this.handleError))
    );
  }


  public checkEmailForImportedUser(email: string) {
    const { imported_user_check } = this.endpoints;
    const payload = {
      email
    };
    // see gatho_users/src/Controller/ImportedCheckController.php for return type
    return lastValueFrom(
      this.httpClient.post<ApiResponse<{ result: "yes" | "no"; }>>(`${this.apiUrl}${imported_user_check}`, payload)
      .pipe(catchError(this.handleError))
    );
  }

  /**
   * Check the user token is still good.
   * @param accessToken
   */
  public checkAccess(_accessToken: string) {
    const { checkAccess } = this.endpoints;
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}${checkAccess}`, {})
      .pipe(catchError(this.handleError))
    );
  }


  public checkAreaTicketApiCall(AreaUUID: string, UserUUID: string) {
    const {checkAreaTicket} = this.endpoints;
    const payload = {
      AreaUUID,
      UserUUID
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}${checkAreaTicket}`, payload)
      .pipe(catchError(this.handleError))
    );
  }


  public checkAreaForOrderAndUserCall(AreaUUID: string, UserUUID: string, orderNumber: string) {
    const {checkAreaForOrderAndUser} = this.endpoints;
    const payload = {
      AreaUUID,
      UserUUID,
      orderNumber
    };
    return lastValueFrom(
      this.httpClient.post<ApiResponse>(`${this.apiUrl}${checkAreaForOrderAndUser}`, payload)
      .pipe(catchError(this.handleError))
    );
  }

  public async getUserProtectedAttributes(_accessToken: string) {
    const { getUserProtectedAttributes } = this.endpoints;
    const response = await lastValueFrom(
      this.httpClient.post<ApiResponse<{ roles: string[]; }>>(`${this.apiUrl}${getUserProtectedAttributes}`, {})
      .pipe(catchError(this.handleError))
    );
    if (response.status) {
      return response.package;
    }
    return;
  }
}
